@keyframes rolling {
  0% {
    transform: rotateZ(0deg);
  }

  50% {
    transform: rotateZ(180deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

.App-header {
  background: #242424;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 4vmin);
  color: white;
}

.Banji {
  width: 100px;
  animation: rolling 1.5s linear infinite;
}

.desc {
  font-size: calc(20px + 2vmin);
  color: #0CACF1;
  margin: 0;
}