@font-face {
  font-family: "Oswald";
  src: url("../assets/fonts/Oswald/Oswald.ttf");
}

body {
  margin: 0;
  font-family: "Oswald"
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}